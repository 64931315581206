import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import constants from '../../constants';
import isPeselValid from '../helpers';

export default class TreatmentsView extends Component {
  static propTypes = {
    events: PropTypes.object,
    treatments: PropTypes.array,
    stats: PropTypes.array,
    prevPageDisabled: PropTypes.bool,
    nextPageDisabled: PropTypes.bool,
    increasePage: PropTypes.func,
    decreasePage: PropTypes.func,
    filters: PropTypes.object,
    filterByPesel: PropTypes.func,
    filterByOperator: PropTypes.func,
    filterByApparatus: PropTypes.func,
    filterByFromDate: PropTypes.func,
    filterByToDate: PropTypes.func,
    filterByBodyPart: PropTypes.func,
    clearFilters: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.bodyPartMap = constants.bodyParts.reduce((map, bodyPart) => {
      map[bodyPart.id] = bodyPart.label;
      return map;
    }, {});
    this.viewPositionMap = constants.viewPositions.reduce((map, viewPosition) => {
      map[viewPosition.id] = viewPosition.label;
      return map;
    }, {});
  }

  render() {
    const filterStyle = {margin: '15px'};
    const paginationStyle = {margin: '15px'};

    const filteredTreatments = this.props.treatments;

    return (
      <div className='treatments-table-div content'>
        <Paper style={{paddingTop: '20px'}}>
          <TextField style={filterStyle} helperText={'Search by pesel'}
            value={this.props.filters.peselFilter}
            onChange={(e) => this.props.filterByPesel(e.target.value.toString())}
            error={!isPeselValid(this.props.filters.peselFilter)}
          />
          <TextField style={filterStyle} helperText={'Search by operator'}
            value={this.props.filters.operatorFilter}
            onChange={(e) => this.props.filterByOperator(e.target.value.toString())}
          />
          <TextField style={filterStyle} helperText={'Search by apparatus'}
            value={this.props.filters.apparatusFilter}
            onChange={(e) => this.props.filterByApparatus(e.target.value.toString())}
          />
          <TextField style={filterStyle} helperText={'From'}
            value={this.props.filters.fromFilter}
            onChange={(e) => this.props.filterByFromDate(e.target.value.toString())}
            type="date"
            InputLabelProps={{shrink: true}}
          />
          <TextField style={filterStyle} helperText={'To'}
            value={this.props.filters.toFilter}
            onChange={(e) => this.props.filterByToDate(e.target.value.toString())}
            type="date"
            InputLabelProps={{shrink: true}}
          />
          <TextField style={filterStyle} helperText={'Search by body part'}
            select
            className='textfield'
            value={this.props.filters.bodyPartFilter}
            onChange={(e) => this.props.filterByBodyPart(e.target.value)}
          >
            <MenuItem value={-1}>{'<SELECT>'}</MenuItem>
            {constants.bodyParts.map((bodyPart, i) => (<MenuItem key={i} value={bodyPart.id}>{bodyPart.label}</MenuItem>))}
          </TextField>
          <Button style={filterStyle} variant="contained" color="primary"  onClick={this.props.clearFilters}>
              Clear all
          </Button>
          <Table className='treatments-table'>
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell>Treatment date</TableCell>
                <TableCell>Patient name</TableCell>
                <TableCell>Patient pesel</TableCell>
                <TableCell>Operator</TableCell>
                <TableCell>Apparatus</TableCell>
                <TableCell numeric>Radiation amount</TableCell>
                <TableCell numeric>Body part</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTreatments.map((t, index) => (
                <TableRow key={index} data-treatment-row>
                  <TableCell data-radiation-amount numeric className={t.radiation_exceeded ? 'warning' : 'ok'}><span className={`dot-${t.radiation_exceeded ? 'warning' : 'ok'}`}>  </span></TableCell>
                  <TableCell data-date>{new Intl.DateTimeFormat('pl-PL', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  }).format(new Date(t.date))}</TableCell>
                  <TableCell data-aux-id>{`${t.patient_first_name} ${t.patient_last_name}`}</TableCell>
                  <TableCell data-pesel onClick={() => this.props.filterByPesel(t.patient_pesel.toString())}>{t.patient_pesel || `N/A (id: ${t.patient_aux_id})`}</TableCell>
                  <TableCell data-operator onClick={() => this.props.events.emit('openModal', {modal: 'operator-stat-view', modalParams:{stats: this.props.stats, operator: t.operator}})}>{t.operator}</TableCell>
                  <TableCell data-apparatus onClick={() => this.props.events.emit('openModal', {modal: 'apparatus-stat-view', modalParams:{stats: this.props.stats, apparatus: t.apparatus}})}>{t.apparatus}</TableCell>
                  <TableCell data-radiation-amount numeric className={t.radiation_exceeded ? 'warning' : 'ok'}>{t.radiation_amount.toFixed(5)}</TableCell>
                  <TableCell>{`${this.bodyPartMap[t.body_part] || ''} ${this.viewPositionMap[t.view_position]}` }</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="treatment-controls">
            <Button style={paginationStyle} variant="contained" color="primary" disabled={this.props.prevPageDisabled} onClick={() => this.props.decreasePage()}>
                Previous page
            </Button>
            <Button style={paginationStyle} variant="contained" color="primary" disabled={this.props.nextPageDisabled} onClick={() => this.props.increasePage()}>
                Next page
            </Button>
          </div>
        </Paper>
      </div>
    );
  }
}
