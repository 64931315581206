import React, {Component} from 'react';
// import PropTypes from 'prop-types';

export default class LoadingView extends Component {
  static propTypes = {
  };

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <div className="modal-overlay">
        <div className="modal-body" style={{width: '200px', textAlign: 'center'}}>
          <h3 className="modal-title">Loading, please wait...</h3>
          <i className="fa fa-spinner fa-spin button-spinner"></i>
        </div>
      </div>);
  }
}
