import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';

export default class AppBarView extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool,
    selectedPage: PropTypes.string,
    onPageSelected: PropTypes.func,
    onRefresh: PropTypes.func
  };

  render() {
    return (
      <div>
        <AppBar position="static" color="primary">
          <Toolbar>
            <Typography variant="h6" color="inherit" style={{marginRight: '20px'}}>RadPal viewer</Typography>
            {this.props.isLoggedIn && (<div>
              <Button data-navigate-treatment-list variant="contained" disabled={this.props.selectedPage === 'treatment-list'} color="primary" onClick={() => this.props.onPageSelected('treatment-list')} style={{marginRight: '20px'}}>Treatment list</Button>
              <Button data-navigate-stats variant="contained" disabled={this.props.selectedPage === 'stats'} color="primary" onClick={() => this.props.onPageSelected('stats')} style={{marginRight: '20px'}}>Optimizations</Button>
              <Button data-navigate-import-treatment variant="contained" disabled={this.props.selectedPage === 'import-treatment'} color="primary" onClick={() => this.props.onPageSelected('import-treatment')} style={{marginRight: '20px'}}>Import treatment</Button>
            </div>)}
            <span style={{flexGrow: 1}}/>
            {this.props.isLoggedIn && <IconButton color="inherit" onClick={this.props.onRefresh} style={{}}><RefreshIcon/></IconButton>}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}
