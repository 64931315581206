import setCookieParser from 'set-cookie-parser';

export default class Cookies {
  constructor(config) {
    this.isTest = config.environment === 'test';
  }

  get(name) {
    return this.isTest ? this.getCookieNode(name) : this.getCookieBrowser(name);
  }

  updateFromHeaders(headers) {
    this.jar = setCookieParser.parse(setCookieParser.splitCookiesString(headers.get('set-cookie')));
  }

  getCookieBrowser(cname) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  getCookieNode(name) {
    if (!this.jar || !this.jar.find((t) => t.name === name)) {
      return '';
    }
    return this.jar.find((t) => t.name === name).value;
  }
}
