import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

export default class ImportTreatment extends Component {
  static propTypes = {
    events: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      message: ''
    };
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({open: false});
  };

  handleSnackbar(snackbar) {
    this.setState({
      open: true,
      message: snackbar.message
    });
  }

  componentDidMount() {
    this.handleSnackbar = this.handleSnackbar.bind(this);
    this.props.events.on('snackbar', this.handleSnackbar);
  }

  componentWillMount() {
    this.props.events.removeListener('snackbar', this.handleSnackbar);
  }

  render() {
    return (
      <div className='snackbars'>
        <Snackbar
          open={this.state.open}
          autoHideDuration={3000}
          onClose={this.handleClose}
          message={<span>{this.state.message}</span>}
        />
      </div>
    );
  }
}
