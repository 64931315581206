import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import constants from '../../constants';

export default class ImportTreatment extends Component {
  static propTypes = {
    importTreatment: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      pesel: '',
      gender: -2,
      dicomDate: '',
      dicomTime: '',
      firstName: '',
      lastName: '',
      operatorName: '',
      apparatus: '',
      bodyPart: -1,
      radiationAmount: 0.0,
      isLoading: false,
      inputCorrect: false,
      viewPosition: 0
    };
  }

  componentDidMount() {

  }

  render() {
    const handleChange = (name) => (event) => {
      this.setState({
        [name]: event.target.value,
        inputCorrect: this.props.importTreatment.validateInput({...this.state, [name]: event.target.value})
      });
    };

    const handleSubmitClick = async () => {
      this.setState({isLoading: true});
      await this.props.importTreatment.importTreatment(this.state);
      this.setState({isLoading: false});
    };

    const textFieldStyle = {margin: '10px'};

    return (
      <div className='import-treatment content'>
        <Paper style={{padding: '30px'}}>
          <form style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'column'}}>
            <TextField
              data-pesel
              label="Pesel/ID"
              value={this.state.pesel}
              onChange={handleChange('pesel')}
              className='textfield'
              style={textFieldStyle}
            />
            <TextField
              data-gender
              select
              label="Gender"
              value={this.state.gender}
              onChange={handleChange('gender')}
              className='textfield'
              style={textFieldStyle}
            >
              <MenuItem value={-2}>{'<SELECT>'}</MenuItem>
              <MenuItem value={0}>M</MenuItem>
              <MenuItem value={1}>F</MenuItem>
              <MenuItem value={-1}>O</MenuItem>
            </TextField>
            <TextField
              data-dicom-date
              label="DICOM date"
              value={this.state.dicomDate}
              onChange={handleChange('dicomDate')}
              className='textfield'
              style={textFieldStyle}
              type="date"
              InputLabelProps={{shrink: true}}
            />
            <TextField
              data-dicom-time
              label="DICOM time"
              value={this.state.dicomTime}
              onChange={handleChange('dicomTime')}
              className='textfield'
              style={textFieldStyle}
              type="time"
              InputLabelProps={{shrink: true}}
            />
            <TextField
              data-first-name
              label="First name"
              value={this.state.firstName}
              onChange={handleChange('firstName')}
              className='textfield'
              style={textFieldStyle}
            />
            <TextField
              data-last-name
              label="Last name"
              value={this.state.lastName}
              onChange={handleChange('lastName')}
              className='textfield'
              style={textFieldStyle}
            />
            <TextField
              data-operator-name
              label="Operator name"
              value={this.state.operatorName}
              onChange={handleChange('operatorName')}
              className='textfield'
              style={textFieldStyle}
            />
            <TextField
              data-apparatus
              label="Apparatus"
              value={this.state.apparatus}
              onChange={handleChange('apparatus')}
              className='textfield'
              style={textFieldStyle}
            />
            <TextField
              data-body-part
              select
              label="Body part"
              className='textfield'
              style={textFieldStyle}
              value={this.state.bodyPart}
              onChange={handleChange('bodyPart')}
            >
              <MenuItem value={-1}>{'<SELECT>'}</MenuItem>
              {constants.bodyParts.map((bodyPart, i) => (<MenuItem key={i} value={bodyPart.id}>{bodyPart.label}</MenuItem>))}
            </TextField>
            <TextField
              data-view-position
              select
              label="View position"
              className='textfield'
              style={textFieldStyle}
              value={this.state.viewPosition}
              onChange={handleChange('viewPosition')}
            >
              {constants.viewPositions.map((viewPosition, i) => (<MenuItem key={i} value={viewPosition.id}>{viewPosition.label}</MenuItem>))}
            </TextField>
            <TextField
              data-radiation-amount
              label="Radiation amount"
              value={this.state.radiationAmount}
              onChange={handleChange('radiationAmount')}
              className='textfield'
              style={textFieldStyle}
              type="number"
            />
            <Button data-submit disabled={this.state.isLoading || !this.state.inputCorrect} color="primary" variant="contained" onClick={handleSubmitClick} style={{width: '100px', marginTop: '20px', marginLeft: 'auto', marginRight: 'auto'}}>Submit</Button>
          </form>
        </Paper>
      </div>
    );
  }
}
