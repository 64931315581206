import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import StatsChart from './statsCharts';
import PieChart from './pieCharts';
import loading from '../img/loading.gif';
import constants from '../../constants';
import moment from 'moment';

export default class Stats extends Component {
  static propTypes = {
    stats: PropTypes.array,
    services: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      dosePerBodyPartStats: [],
      isLoading: false,
      exceededTreatments: []
    };
  }

  async componentDidMount() {
    const fromDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    const fromFilter = moment(fromDate).format('YYYY-MM-DD');
    const treatments = await this.props.services.django.getTreatments(1, {peselFilter: '', operatorFilter: '', apparatusFilter: '', fromFilter});
    this.setState({exceededTreatments: treatments.data.filter((t) => t.radiation_exceeded), isLoading: false});
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div data-stats-page className='import-treatment content'><img src={loading} style={{width: '100%', margin: 'auto'}} alt={'Loading animation'}/></div>
      );
    }
    return (
      <div data-stats-page className='import-treatment content'>
        <Paper style={{padding: '30px'}}>
          <div>Average radiation dose per body part (all time)</div>
          <br/>
          <StatsChart
            stats={this.props.stats}
            sortingFunc={(a,b) => (`${a.body_part}${a.view_position}${a.operator}${a.apparatus}`.localeCompare(`${b.body_part}${b.view_position}${b.operator}${b.apparatus}`))}
            labelingFunc={(s) => `${constants.bodyParts.find((x) => x.id === s.body_part).label} ${constants.viewPositions.find((x) => x.id === s.view_position).label}`}
            labelsToDisplay={{operator: false, apparatus: false, month: false}}
            groupingFunc={(stat) => (s) => s.body_part === stat.body_part && s.view_position === stat.view_position}
            environment={this.props.services.config.environment}
          />
          <br/>
          <br/>
          <div>Average radiation dose per body part (last 12 months)</div>
          <br/>
          <StatsChart
            stats={this.props.stats.filter((s) => new Date(s.month) >= new Date().setFullYear(new Date().getFullYear() - 1))}
            sortingFunc={(a,b) => (new Date(a.month) - new Date(b.month) || `${a.body_part}${a.view_position}${a.operator}${a.apparatus}`.localeCompare(`${b.body_part}${b.view_position}${b.operator}${b.apparatus}`))}
            labelingFunc={(s) => `${s.month} ${constants.bodyParts.find((x) => x.id === s.body_part).label} ${constants.viewPositions.find((x) => x.id === s.view_position).label}`}
            labelsToDisplay={{operator: false, apparatus: false, month: true}}
            groupingFunc={(stat) => (s) => s.body_part === stat.body_part && s.view_position === stat.view_position && s.month === stat.month}
            environment={this.props.services.config.environment}
          />
          <br/>
          <br/>
          <div>Average radiation dose for operator</div>
          <br/>
          <StatsChart
            stats={this.props.stats}
            sortingFunc={(a,b) => (`${a.operator}${a.body_part}${a.view_position}${a.apparatus}`.localeCompare(`${b.operator}${b.body_part}${b.view_position}${b.apparatus}`))}
            labelingFunc={(s) => s.operator}
            labelsToDisplay={{operator: true, apparatus: false, month: false}}
            groupingFunc={(stat) => (s) => s.body_part === stat.body_part && s.view_position === stat.view_position && s.operator === stat.operator}
            environment={this.props.services.config.environment}
          />
          <br/>
          <br/>
          <div>Average radiation dose for apparatus</div>
          <br/>
          <StatsChart
            stats={this.props.stats}
            sortingFunc={(a,b) => (`${a.apparatus}${a.body_part}${a.view_position}${a.operator}`.localeCompare(`${b.apparatus}${b.body_part}${b.view_position}${b.operator}`))}
            labelingFunc={(s) => s.apparatus}
            labelsToDisplay={{operator: false, apparatus: true, month: false}}
            groupingFunc={(stat) => (s) => s.body_part === stat.body_part && s.view_position === stat.view_position && s.apparatus === stat.apparatus}
            environment={this.props.services.config.environment}
          />
          <br/>
          <br/>
          <div>Number of body parts with exceeded dose (last 12 months)</div>
          <br/>
          <PieChart
            treatments={this.state.exceededTreatments}
            labelingFunc={(t) => `${constants.bodyParts.find((x) => x.id === t.body_part).label} ${constants.viewPositions.find((x) => x.id === t.view_position).label}`}
            labelsToDisplay={{operator: false, apparatus: false, month: false}}
            groupingFunc={(treatment) => (t) => t.body_part === treatment.body_part && t.view_position === treatment.view_position}
            environment={this.props.services.config.environment}
          />
          <br/>
          <br/>
          <div>Number of operators that exceeded dose (last 12 months)</div>
          <br/>
          <PieChart
            treatments={this.state.exceededTreatments}
            labelingFunc={(t) => t.operator}
            labelsToDisplay={{operator: false, apparatus: false, month: false}}
            groupingFunc={(treatment) => (t) => t.operator === treatment.operator}
            environment={this.props.services.config.environment}
          />
        </Paper>
      </div>
    );
  }
}
