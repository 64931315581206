
/* eslint-disable import/first, no-undef, no-unused-vars */
import 'regenerator-runtime/runtime';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
require('babel-core/register');
require('babel-polyfill');
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';
import Builder from './builder';
require('./styles/app.scss');
const theme = createMuiTheme({typography: {useNextVariants: true}});

(async() => {
  const services = await Builder.build();
  ReactDOM.render(
    (<MuiThemeProvider theme={theme}>
      <App services={services}/>
    </MuiThemeProvider>),
    document.getElementById('app')
  );
})();

/* eslint-enable import/first no-undef */
