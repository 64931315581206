import React, {Component} from 'react';
import {Doughnut} from 'react-chartjs-2';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import constants from '../../constants';

export default class PieChart extends Component {
  static propTypes = {
    environment: PropTypes.string,
    treatments: PropTypes.array,
    labelingFunc: PropTypes.func, // function for calculating labels for the chart elements
    groupingFunc: PropTypes.func,
    labelsToDisplay: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    this.countTreatments();
  }

  countTreatments() {
    const dataContainer = [];
    for (const stat of this.props.treatments) {
      const existingStat = dataContainer.find(this.props.groupingFunc(stat));
      if (existingStat) {
        existingStat.count += 1;
      } else {
        stat.count = 1;
        dataContainer.push(stat);
      }
    }
    this.setState({data: dataContainer});
  }

  createTitle(statObj) {
    const bodyPartName = constants.bodyParts.find((x) => x.id === statObj.body_part).label;
    const viewPositionName = constants.viewPositions.find((x) => x.id === statObj.view_position).label;
    const labels = [`Body part: ${bodyPartName} ${viewPositionName}`];
    if (this.props.labelsToDisplay.operator) {
      labels.push(`Operator: ${statObj.operator}`);
    }
    if (this.props.labelsToDisplay.apparatus) {
      labels.push(`Apparatus: ${statObj.apparatus}`);
    }
    if (this.props.labelsToDisplay.month) {
      labels.push(`Date: ${statObj.month}`);
    }
    return labels;
  }

  render() {
    if (this.props.environment === 'test') {
      return <div>ReactCharts do not work in Node.js</div>;
    }

    const dataContainer = [];
    for (const stat of this.props.treatments) {
      const existingStat = dataContainer.find(this.props.groupingFunc(stat));
      if (existingStat) {
        existingStat.count += 1;
      } else {
        stat.count = 1;
        dataContainer.push(stat);
      }
    }
    const chartLabels = dataContainer.map(this.props.labelingFunc);
    const chartValues = dataContainer.map((s) => (s.count));

    if (this.props.environment === 'test') {
      return <div>ReactCharts do not work in Node.js</div>;
    }

    const dynamicColors = function () {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      return `rgb(${r}, ${g}, ${b})`;
    };

    const dataColors = dataContainer.map(() => dynamicColors());
    const chartData = {
      labels: chartLabels,
      datasets: [{
        data: chartValues,
        backgroundColor: dataColors
      }]
    };

    return (
      <div className='content'>
        <Paper style={{padding: '30px'}}>
          <Doughnut data={chartData} width={1116} height={250}/>
        </Paper>
      </div>
    );
  }
}
