import React, {Component} from 'react';
import {Bar} from 'react-chartjs-2';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import constants from '../../constants';

export default class Charts extends Component {
  static propTypes = {
    treatments: PropTypes.array,
    environment: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {

  }

  componentWillMount() {

  }

  render() {
    if (this.props.environment === 'test') {
      return <div>ReactCharts do not work in Node.js</div>;
    }

    const sortedTreatments = this.props.treatments.concat().sort((a,b) => new Date(a.date) - new Date(b.date));
    const doseLabels = sortedTreatments.map((t) => (new Date(t.date)).toLocaleDateString());
    const doseData = sortedTreatments.map((t) => ({y: t.radiation_amount, obj: t}));
    const doseColors = sortedTreatments.map((t) => t.radiation_exceeded ? 'rgba(255, 0, 0, 0.2)' : 'rgba(0, 255, 0, 0.2)');

    const options = {
      responsive: true,
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
        }],
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'mGy*cm²'
          },
          ticks: {
            beginAtZero: true
          }
        }]
      },
      tooltips: {
        callbacks: {
          title(tooltipItem, data) {
            const {datasets: [{data: objects}]} = data;
            const [{index: itemIndex}] = tooltipItem;
            const {obj: treatment} = objects[itemIndex];
            const bodyPartName = constants.bodyParts.find((x) => x.id === treatment.body_part).label;
            const viewPositionName = constants.viewPositions.find((x) => x.id === treatment.view_position).label;
            const date = (new Date(treatment.date)).toLocaleDateString();
            return [`Patient: ${treatment.patient_pesel}`, `Body part: ${bodyPartName} ${viewPositionName}`, `Operator: ${treatment.operator}`, `Date: ${date}`];
          }
        }
      }
    };

    const chartData = {
      labels: doseLabels,
      datasets: [{
        data: doseData,
        backgroundColor: doseColors
      }]
    };

    return (
      <div className='import-treatment content'>
        <Paper style={{padding: '30px'}}>
          <Bar data={chartData} width={1116} height={250} options={options} />
        </Paper>
      </div>
    );
  }
}
