import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export default class Login extends Component {
  static propTypes = {
    services: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      username: this.props.services.config.username,
      password: this.props.services.config.password,
      isLoading: false
    };
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  render() {
    const handleChange = (name) => (event) => {
      this.setState({
        [name]: event.target.value
      });
    };

    const handleSubmitClick = async () => {
      this.setState({isLoading: true});
      await this.props.services.django.login(this.state.username, this.state.password);
      if (!this.willUnmount) {
        this.setState({isLoading: false});
      }
    };

    const textFieldStyle = {margin: '10px'};
    const disabled = this.state.isLoading || !this.state.username || !this.state.password;

    return (
      <div className='login content'>
        <Paper style={{padding: '30px'}}>
          <form style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'column'}}>
            <TextField
              data-username-field
              label='Username'
              value={this.state.username}
              onChange={handleChange('username')}
              className='textfield'
              style={textFieldStyle}
            />
            <TextField
              data-password-field
              label='Password'
              value={this.state.password}
              onChange={handleChange('password')}
              className='textfield'
              style={textFieldStyle}
              type='password'
            />
            <Button
              data-login-button
              disabled={disabled}
              color="primary"
              variant="contained"
              onClick={handleSubmitClick}
              style={{width: '100px', marginTop: '20px', marginLeft: 'auto', marginRight: 'auto'}}
            >Login</Button>
          </form>
        </Paper>
      </div>
    );
  }
}
