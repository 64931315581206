export default {
  bodyParts: [
    {id: -1, label: 'UNKNOWN'},
    {id: 1, label: 'SKULL'},
    {id: 2, label: 'CSPINE'},
    {id: 3, label: 'TSPINE'},
    {id: 4, label: 'LSPINE'},
    {id: 5, label: 'SSPINE'},
    {id: 6, label: 'COCCYX'},
    {id: 7, label: 'CHEST'},
    {id: 8, label: 'CLAVICLE'},
    {id: 9, label: 'BREAST'},
    {id: 10, label: 'ABDOMEN'},
    {id: 11, label: 'PELVIS'},
    {id: 12, label: 'HIP'},
    {id: 13, label: 'SHOULDER'},
    {id: 14, label: 'ELBOW'},
    {id: 15, label: 'KNEE'},
    {id: 16, label: 'ANKLE'},
    {id: 17, label: 'HAND'},
    {id: 18, label: 'FOOT'},
    {id: 19, label: 'EXTREMITY'},
    {id: 20, label: 'HEAD'},
    {id: 21, label: 'HEART'},
    {id: 22, label: 'NECK'},
    {id: 23, label: 'LEG'},
    {id: 24, label: 'ARM'},
    {id: 25, label: 'JAW'},
    {id: 26, label: 'THORAX'},
    {id: 100, label: 'SIUSIAK / PUSIA'},
    {id: 9999, label: 'TEST'}
  ],
  viewPositions: [
    {id: 0, label: ''},
    {id: 1, label: 'AP'},
    {id: 2, label: 'PA'},
    {id: 3, label: 'LAT'}
  ]
};
