import React, {Component} from 'react';
import {Bar} from 'react-chartjs-2';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import constants from '../../constants';

export default class StatsChart extends Component {
  static propTypes = {
    environment: PropTypes.string,
    stats: PropTypes.array,
    sortingFunc: PropTypes.func, // function used for sorting elements in chart
    labelingFunc: PropTypes.func, // function for calculating labels for the chart elements
    groupingFunc: PropTypes.func,
    labelsToDisplay: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      displayedData: [],
      bodyPartFilter: undefined,
      viewPositionFilter: constants.viewPositions[0].id
    };
  }

  componentDidMount() {
    this.calculateDosePerBodyPart();
  }

  calculateDosePerBodyPart() {
    const dataContainer = [];
    for (const stat of this.props.stats) {
      const existingStat = dataContainer.find(this.props.groupingFunc(stat));
      if (existingStat) {
        existingStat.radiation_dose_count += stat.radiation_dose_count;
        existingStat.radiation_dose_sum += stat.radiation_dose_sum;
      } else {
        dataContainer.push(stat);
      }
    }
    this.setState({data: dataContainer}, () => this.applyFilters());
  }

  applyFilters() {
    let dataContainer = this.state.data.filter((s) => this.state.bodyPartFilter === undefined || s.body_part === this.state.bodyPartFilter);
    dataContainer = dataContainer.filter((s) => this.state.viewPositionFilter === undefined || s.view_position === this.state.viewPositionFilter);
    this.setState({displayedData: dataContainer});
  }

  createTitle(statObj) {
    const bodyPartName = constants.bodyParts.find((x) => x.id === statObj.body_part).label;
    const viewPositionName = constants.viewPositions.find((x) => x.id === statObj.view_position).label;
    const labels = [`Body part: ${bodyPartName} ${viewPositionName}`];
    if (this.props.labelsToDisplay.operator) {
      labels.push(`Operator: ${statObj.operator}`);
    }
    if (this.props.labelsToDisplay.apparatus) {
      labels.push(`Apparatus: ${statObj.apparatus}`);
    }
    if (this.props.labelsToDisplay.month) {
      labels.push(`Date: ${statObj.month}`);
    }
    return labels;
  }

  calculateDoseExceeded(stat) {
    const thresholdsPerBodyPart = constants.thresholds[stat.body_part];
    if (thresholdsPerBodyPart) {
      return (stat.radiation_dose_sum / stat.radiation_dose_count) > thresholdsPerBodyPart[stat.view_position];
    }
    return false;
  }

  render() {
    const sortedTreatments = this.state.displayedData.sort(this.props.sortingFunc);
    const chartLabels = sortedTreatments.map(this.props.labelingFunc);
    const chartValues = sortedTreatments.map((s) => ({y: s.radiation_dose_sum / s.radiation_dose_count, obj: s, title: (obj) => this.createTitle(obj)}));
    const doseColors = sortedTreatments.map((s) => this.calculateDoseExceeded(s) ? 'rgba(255, 0, 0, 0.2)' : 'rgba(0, 255, 0, 0.2)');

    if (this.props.environment === 'test') {
      return <div>ReactCharts do not work in Node.js</div>;
    }

    const options = {
      responsive: true,
      legend: {
        display: false
      },
      filters: this.state.displayedData,
      scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Radiation dose'
          },
          ticks: {
            beginAtZero: true
          }
        }]
      },
      tooltips: {
        callbacks: {
          title(tooltipItem, data) {
            const {datasets: [{data: objects}]} = data;
            const [{index: itemIndex}] = tooltipItem;
            const {obj: stat, title: titleCallback} = objects[itemIndex];
            return titleCallback(stat);
          }
        }
      }
    };

    const chartData = {
      labels: chartLabels,
      datasets: [{
        data: chartValues,
        backgroundColor: doseColors
      }]
    };

    return (
      <div className='content'>
        <Paper style={{padding: '30px'}}>
          <TextField style={{margin: '15px'}} helperText={'Search by body part'}
            select
            className='textfield'
            value={this.state.bodyPartFilter === undefined ? '' : this.state.bodyPartFilter}
            onChange={(e) => {
              this.setState({bodyPartFilter: e.target.value}, () => this.applyFilters());
            }}
          >
            <MenuItem value={undefined}>{'<SELECT>'}</MenuItem>
            {constants.bodyParts.map((bodyPart, i) => (<MenuItem key={i} value={bodyPart.id}>{bodyPart.label}</MenuItem>))}
          </TextField>
          <TextField style={{margin: '15px'}} helperText={'Search by view position'}
            select
            className='textfield'
            value={this.state.viewPositionFilter === undefined ? '' : this.state.viewPositionFilter}
            onChange={(e) => {
              this.setState({viewPositionFilter: e.target.value}, () => this.applyFilters());
            }}
          >
            <MenuItem value={undefined}>{'<SELECT>'}</MenuItem>
            {constants.viewPositions.map((viewPosition, i) => (<MenuItem key={i} value={viewPosition.id}>{viewPosition.label}</MenuItem>))}
          </TextField>
          <Bar data={chartData} options={options} width={1116} height={250}/>
        </Paper>
      </div>
    );
  }
}
