import fetch from 'node-fetch';
/* eslint-disable camelcase */

export default class Django {
  constructor(config, events, cookies) {
    this.config = config;
    this.events = events;
    this.cookies = cookies;
    this.isLoggedIn = false;
  }

  async login(username, password) {
    const url = `${this.config.djangoUrl}/admin/login/`;
    const body = `username=${username}&password=${password}`;

    try {
      const {json, headers} = await this.postRequest(url, body);
      this.cookies.updateFromHeaders(headers);
      this.isLoggedIn = true;
      this.events.emit('loggedIn');
      this.events.emit('snackbar', {message: 'Successfully logged in!'});
      return json;
    } catch (err) {
      this.events.emit('snackbar', {message: 'Login unsuccessful'});
      console.log(err);
      return null;
    }
  }

  async getTreatments(page, filters) {
    const {peselFilter, operatorFilter, apparatusFilter, fromFilter, toFilter, bodyPartFilter} = filters;
    let url = `${this.config.djangoUrl}/treatments/treatments-list/?page=${page}&pesel=${peselFilter}&operator=${encodeURIComponent(operatorFilter)}&apparatus=${encodeURIComponent(apparatusFilter)}`;
    if (fromFilter) {
      url = `${url}&date_from=${fromFilter}`;
    }
    if (toFilter) {
      url = `${url}&date_to=${toFilter}`;
    }
    if (bodyPartFilter && bodyPartFilter > 0) {
      url = `${url}&body_part=${bodyPartFilter}`;
    }
    return (await this.getRequest(url)).json;
  }

  async getStats(filters) {
    const {operatorFilter, apparatusFilter, bodyPartFilter, viewPositionFilter, fromFilter, toFilter} = filters;
    let url = `${this.config.djangoUrl}/treatments/stats-list/?operator=${encodeURIComponent(operatorFilter) || ''}`;
    if (apparatusFilter) {
      url = `${url}&apparatus=${encodeURIComponent(apparatusFilter)}`;
    }
    if (fromFilter) {
      url = `${url}&date_from=${fromFilter}`;
    }
    if (toFilter) {
      url = `${url}&date_to=${toFilter}`;
    }
    if (bodyPartFilter && bodyPartFilter > 0) {
      url = `${url}&body_part=${bodyPartFilter}`;
    }
    if (viewPositionFilter && viewPositionFilter > 0) {
      url = `${url}&view_position=${viewPositionFilter}`;
    }
    return (await this.getRequest(url)).json;
  }

  async getThresholds() {
    const url = `${this.config.djangoUrl}/treatments/thresholds/`;
    return (await this.getRequest(url, {})).json;
  }

  async importTreatment(patientId, patientGender, patientFirstName, patientLastName, operatorName, apparatus, dicomDate, dicomTime, radiationAmount, bodyPart, viewPosition) {
    const url = `${this.config.djangoUrl}/treatments/import-treatment/`;
    const dicomDateTime = `${dicomDate} ${dicomTime}`;
    const body = `patient_id=${patientId}&patient_gender=${patientGender}&patient_first_name=${patientFirstName}&patient_last_name=${patientLastName}&operator_name=${operatorName}&apparatus=${apparatus}&dicom_date=${dicomDateTime}&radiation_amount=${radiationAmount}&body_part=${bodyPart}&view_position=${viewPosition}`;
    return (await this.postRequest(url, body)).json;
  }

  /* HELPERS */
  async getRequest(url) {
    const request = fetch(url, {
      headers: {
        cookie: `csrftoken=${this.cookies.get('csrftoken')}; sessionid=${this.cookies.get('sessionid')}`
      },
      credentials: 'include'
    });

    return await this.sendRequest(request);
  }

  async postRequest(url, body = null) {
    const request = fetch(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRFToken': this.cookies.get('csrftoken'),
        sessionid: this.cookies.get('sessionid'),
        cookie: `csrftoken=${this.cookies.get('csrftoken')}; sessionid=${this.cookies.get('sessionid')}`
      },
      credentials: 'include',
      body,
      method: 'POST'
    });

    return await this.sendRequest(request);
  }

  async sendRequest(request) {
    const response = await request;
    if (!response || response.status !== 200) {
      throw new Error(`Request unsuccessful: ${response.status} ${response.statusText}`);
    }
    const json = await response.json();
    if (!json.success) {
      console.log(json);
      throw new Error(`Request unsuccessful`);
    }
    return {
      headers: response.headers,
      json
    };
  }
}
/* eslint-enable*/
