import React, {Component} from 'react';
import PropTypes from 'prop-types';
import OperatorStatView from '../views/operatorStatView';
import ApparatusStatView from '../views/apparatusStatView';
import LoadingView from '../views/loadingView';

export default class Modal extends Component {
  static propTypes = {
    services: PropTypes.object,
    isLoading: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: '',
      modalParams: {}
    };
    this.onOpenModal = this.onOpenModal.bind(this);
  }

  componentDidMount() {
    this.props.services.events.on('openModal', this.onOpenModal);
  }

  componentWillUnmount() {
    this.props.services.events.removeListener('openModal', this.onOpenModal);
  }

  onOpenModal(args) {
    this.setState({modal: args.modal, modalParams: args.modalParams});
  }

  render() {
    const close = () => this.setState({modal: '', modalParams: {}});

    if (this.props.isLoading) {
      return (
        <LoadingView/>
      );
    }

    return (
      <div>
        {this.state.modal === 'operator-stat-view' && <OperatorStatView
          stats={this.state.modalParams.stats}
          operator={this.state.modalParams.operator}
          close={close}
          services={this.props.services}
          environment={this.props.services.config.environment}
        />}
        {this.state.modal === 'apparatus-stat-view' && <ApparatusStatView
          stats={this.state.modalParams.stats}
          apparatus={this.state.modalParams.apparatus}
          close={close}
          services={this.props.services}
          environment={this.props.services.config.environment}
        />}
      </div>);
  }
}
