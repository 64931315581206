import Django from 'services/django';
import ImportTreatment from 'services/importTreatment';
import {EventEmitter} from 'events';
import {configTest, configProd, configDev} from '../config';
import Cookies from 'services/cookies';

export default class Builder {
  static getConfig() {
    if (process.env.CONFIG_ENV === 'test') {
      return configTest;
    }
    if (process.env.CONFIG_ENV === 'dev') {
      return configDev;
    }
    if (process.env.CONFIG_ENV === 'prod') {
      return configProd;
    }
    throw new Error(`Unrecognized environment: ${process.env.CONFIG_ENV}`);
  }

  static async build() {
    const config = Builder.getConfig();
    const events = new EventEmitter();
    const cookies = new Cookies(config);
    const django = new Django(config, events, cookies);
    const importTreatment = new ImportTreatment(config, events, django);

    if (config.autoLogin) {
      await django.login(config.username, config.password);
    }

    const services = {
      config,
      events,
      django,
      importTreatment
    };

    return services;
  }
}
