import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AppBarView from 'views/appBarView';
import TreatmentsView from 'views/treatmentsView';
import ImportTreatment from 'components/importTreatment';
import Charts from 'components/charts';
import Modal from 'components/modal';
import Snackbars from 'components/snackbars';
import Login from 'components/login';
import Stats from 'components/stats';
import {CSSTransitionGroup} from 'react-transition-group';
import isPeselValid from '../helpers';
import constants from '../../constants';

export default class App extends Component {
  static propTypes = {
    services: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.initialTreatmentFilters = {peselFilter: '', operatorFilter: '', apparatusFilter: '', fromFilter: '', toFilter: '', bodyPartFilter: 0};
    this.state = {
      treatments: [],
      stats: [],
      thresholds: {},
      selectedPage: 'treatment-list',
      treatmentsPage: 1,
      treatmentsFilters: this.initialTreatmentFilters,
      statsFilters: this.initialStatsFilters,
      isLoading: false
    };
    this.timeout = {};
  }

  componentDidMount() {
    this.onLoggedIn = this.onLoggedIn.bind(this);
    this.onTreatmentImported = this.onTreatmentImported.bind(this);
    this.props.services.events.on('loggedIn', this.onLoggedIn);
    this.props.services.events.on('treatmentImported', this.onTreatmentImported);
  }

  componentWillUnmount() {
    this.props.services.events.removeListener('loggedIn', this.onLoggedIn);
    this.props.services.events.removeListener('treatmentImported', this.onTreatmentImported);
  }

  async onLoggedIn() {
    this.setState({isLoading: true});
    await this.loadThresholds();
    await this.reloadStats();
    await this.reloadTreatments();
    this.setState({isLoading: false});
  }

  async onTreatmentImported() {
    this.setState({selectedPage: 'treatment-list'});
    await this.reloadTreatments();
  }

  async reloadTreatments(page = null, filters = null) {
    const myFilters = filters ? filters : this.state.treatmentsFilters;
    if (isPeselValid(myFilters.peselFilter)) {
      const treatments = await this.props.services.django.getTreatments(page ? page : this.state.treatmentsPage, myFilters);
      this.setState({treatments: treatments.data});
      this.props.services.events.emit('treatmentsReloaded');
    }
  }

  async reloadStats() {
    const statsResponse = await this.props.services.django.getStats({operatorFilter: '', apparatusFilter: '', fromFilter: '', toFilter: '', bodyPartFilter: 0, viewPositionFilter: 0});
    this.setState({stats: statsResponse.data});
  }

  async loadThresholds() {
    const thresholds = await  this.props.services.django.getThresholds();
    constants.thresholds = thresholds.data;
    this.setState({thresholds: thresholds.data});
  }

  render() {
    const handleLoginClick = async () => this.props.services.django.login();
    const handleRefresh = async() => {
      await this.reloadTreatments();
      await this.reloadStats();
      this.props.services.events.emit('snackbar', {message: 'Refreshed'});
    };
    const {isLoggedIn} = this.props.services.django;

    const applyNewFilters = async(newFilters) => {
      const treatmentsPage = 1;
      this.setState({treatmentsPage, treatmentsFilters: newFilters});
      clearTimeout(this.timeout);
      setTimeout(async () => this.reloadTreatments(treatmentsPage, newFilters), this.props.services.config.filterRefreshTimeout);
    };

    return (
      <div>
        <CSSTransitionGroup transitionName="example" transitionAppear={true} transitionAppearTimeout={400} transitionEnter={false} transitionEnterTimeout={400} transitionLeave={false}>
          <AppBarView
            isLoggedIn={isLoggedIn}
            onLoginClick={handleLoginClick}
            onRefresh={handleRefresh}
            selectedPage={this.state.selectedPage}
            onPageSelected={(selectedPage) => this.setState({selectedPage})}
          />
          <div className='content' style={{paddingTop: '60px', paddingBottom: '60px'}}>
            {isLoggedIn && (<div>
              <CSSTransitionGroup transitionName="example" transitionAppear={true} transitionAppearTimeout={400} transitionEnter={true} transitionEnterTimeout={400} transitionLeave={false}>
                {this.state.selectedPage === 'treatment-list' &&
                  <div>
                    <Charts
                      treatments={this.state.treatments}
                      environment={this.props.services.config.environment}
                    />

                    <TreatmentsView
                      events={this.props.services.events}
                      filterRefreshTimeout={500}
                      treatments={this.state.treatments}
                      stats={this.state.stats}
                      thresholds={this.state.thresholds}
                      prevPageDisabled={this.state.treatmentsPage === 1}
                      nextPageDisabled={this.state.treatments.length !== 50}  // There should be 50 treatments per page
                      filters={this.state.treatmentsFilters}
                      filterByPesel={async (peselFilter) => applyNewFilters({...this.state.treatmentsFilters, peselFilter})}
                      filterByOperator={async (operatorFilter) => applyNewFilters({...this.state.treatmentsFilters, operatorFilter})}
                      filterByApparatus={async (apparatusFilter) => applyNewFilters({...this.state.treatmentsFilters, apparatusFilter})}
                      filterByFromDate={async (fromFilter) => applyNewFilters({...this.state.treatmentsFilters, fromFilter})}
                      filterByToDate={async (toFilter) => applyNewFilters({...this.state.treatmentsFilters, toFilter})}
                      filterByBodyPart={async (bodyPartFilter) => applyNewFilters({...this.state.treatmentsFilters, bodyPartFilter})}
                      clearFilters={async () => applyNewFilters({...this.initialTreatmentFilters})}
                      increasePage={async () => {
                        const treatmentsPage = this.state.treatmentsPage + 1;
                        this.setState({treatmentsPage});
                        await this.reloadTreatments(treatmentsPage);
                      }}
                      decreasePage={async () => {
                        const treatmentsPage = this.state.treatmentsPage - 1;
                        this.setState({treatmentsPage});
                        await this.reloadTreatments(treatmentsPage);
                      }}
                    />
                  </div>
                }

                {this.state.selectedPage === 'import-treatment' && <ImportTreatment importTreatment={this.props.services.importTreatment}/>}
                {
                  this.state.selectedPage === 'stats' &&
                  <Stats
                    services={this.props.services}
                    stats={this.state.stats}
                  />
                }
              </CSSTransitionGroup></div>)}

            {!isLoggedIn && (
              <Login services={this.props.services}/>
            )}
          </div>
          <Snackbars events={this.props.services.events}/>
          <Modal
            services={this.props.services}
            isLoading={this.state.isLoading}
          />
        </CSSTransitionGroup>
      </div>
    );
  }
}
