import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CloseIco from '../img/close-ico.svg';
import StatsChart from '../components/statsCharts';

export default class OperatorStatView extends Component {
  static propTypes = {
    operator: PropTypes.string,
    close: PropTypes.func,
    stats: PropTypes.array,
    environment: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <div className="modal-overlay">
        <div className="modal-body">
          <button className="modal-close" onClick={this.props.close}>
            <img src={CloseIco} alt="close"/>
          </button>
          <h3 className="modal-title">Average radiation dose applied by {this.props.operator}</h3>
          <StatsChart
            stats={this.props.stats.filter((s) => s.operator === this.props.operator)}
            sortingFunc={(a,b) => (new Date(a.month) - new Date(b.month))}
            labelingFunc={(s) => {
              const date = new Date(s.month);
              return `${date.getUTCMonth() + 1}.${date.getUTCFullYear()}`;
            }}
            labelsToDisplay={{operator: true, apparatus: false, month: true}}
            groupingFunc={(stat) => (s) => s.body_part === stat.body_part && s.view_position === stat.view_position}
            environment={this.props.environment}
          />
        </div>
      </div>);
  }
}
