export const configCommon = {
  djangoUrl: 'https://radpal.cloud:8888',
  autoLogin: false,
  filterRefreshTimeout: 500,
  username: '',
  password: ''
};

export const configTest = {
  ...configCommon,
  environment: 'test'
};

export const configDev = {
  ...configCommon,
  environment: 'dev',
  username: 'test',
  password: '12345',
  autoLogin: false
};

export const configProd = {
  ...configCommon,
  environment: 'prod'
};
