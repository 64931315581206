export default class importTreatment {
  constructor(config, events, django) {
    this.config = config;
    this.events = events;
    this.django = django;
    this.isLoggedIn = false;
  }

  validateInput(state) {
    if (
      state.pesel &&
      state.gender !== -2 &&
      state.dicomDate &&
      state.dicomTime &&
      state.firstName &&
      state.lastName &&
      state.operatorName &&
      state.apparatus &&
      state.bodyPart !== -1 &&
      state.radiationAmount && state.radiationAmount >= 0
    ) {
      return true;
    }
    return false;
  }

  async importTreatment(state) {
    try {
      await this.django.importTreatment(state.pesel, state.gender, state.firstName, state.lastName, state.operatorName, state.apparatus, state.dicomDate, state.dicomTime, state.radiationAmount, state.bodyPart, state.viewPosition);
      this.events.emit('snackbar', {message: 'Successfully imported treatment'});
      this.events.emit('treatmentImported');
      return true;
    } catch (err) {
      console.log(err);
      this.events.emit('snackbar', {message: 'Import unsuccessful'});
      return false;
    }
  }
}
/* eslint-enable*/
